export default [
  {
    id: 1,
    name: "Singapore Institute of Technology",
    major: "Computer Science",
    degree: "Bachelor",
    dated: "2010 - 2014",
    cgpa: "9.12",
  },
];
