export default [
  { type: "Blockchain", level: 95 },
  { type: "Ethereum", level: 95 },
  { type: "Cosmos", level: 90 },
  { type: "Polkadot", level: 90 },
  { type: "Solana", level: 90 },
  { type: "Substrate", level: 90 },
  { type: "Hardhat", level: 95 },
  { type: "Truffle", level: 95 },
  { type: "Django", level: 90 },
  { type: "Flask", level: 90 },
  { type: "Gin / Fiber", level: 85 },
  { type: "React.js / Next / Redux", level: 95 },
  { type: "Vue.js / Nuxt / Vuex", level: 90 },
  { type: "Web3.js", level: 92 },
  { type: "Angular", level: 90 },
  { type: "Node.js", level: 93 },
  { type: "Nest / Express", level: 93 },
  { type: "Laravel", level: 80 },
  { type: "Smart Contract", level: 90 },
  { type: "HyperLedger", level: 82 },
  { type: "Jest", level: 90 },
  { type: "Cypress", level: 92 },
  { type: "WordPress", level: 99 },
  { type: "jQuery", level: 92 },
  { type: "Solidity", level: 92 },
  { type: "Python", level: 92 },
  { type: "Golang", level: 92 },
  { type: "Rust", level: 90 },
  { type: "jQuery", level: 92 },
  { type: "BootStrap", level: 90 },
  { type: "PHP", level: 89 },
  { type: "HTML", level: 99 },
  { type: "CSS", level: 98 },
  { type: "JavaScript", level: 97 },
  { type: "TypeScript", level: 95 },
];
