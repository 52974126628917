import morpheus from "../assets/images/morpheus.png";
import privi from "../assets/images/privi.png";
import advancedblockchain from "../assets/images/advancedblockchain.svg";
import chaingpt from "../assets/images/chaingpt.png";
import clubrare from "../assets/images/clubrare.svg";

export default [
  {
    id: 7,
    name: "ChainGPT",
    designation: "Senior Blockchain Engineer",
    dated: "Sep 2023 - Oct 2024",
    logo: chaingpt,
    companyLink: "https://nft.chaingpt.org/",
    description:
      "<p>ChainGPT is an advanced AI model that assists individuals and businesses with everything related to Crypto and Blockchain technology.",
  },
  {
    id: 6,
    name: "Clubrare",
    designation: "Senior Blockchain Developer",
    dated: "Feb 2022 - Sep 2023",
    logo: clubrare,
    companyLink: "https://app.clubrare.xyz/",
    description:
      "<p>ClubRare is an eCommerce ecosystem that harnesses Real World Assets tokenization (RWA) to improve the way luxury goods are discovered, authenticated, and traded.</p>",
  },
  {
    id: 4,
    name: "Advanced Blockchain AG",
    designation: "Blockchain Engineer",
    dated: "March 2020 - April 2022",
    logo: advancedblockchain,
    companyLink: "https://www.advancedblockchain.com/",
    description:
      "<p>Advanced Blockchain AG is a publicly listed investor, incubator, and partner in the blockchain industry. ",
  },
  {
    id: 3,
    name: "Privi Protocol",
    designation: "Blockchain Developer",
    dated: "Feb 2018 - March 2020",
    logo: privi,
    companyLink: "https://priviprotocol.io/",
    description:
      "<p>Privi is a collection of decentralized apps designed to take the power away from the elite, and distribute it to the world.",
  },
  {
    id: 2,
    name: "Morpheus Labs, Inc",
    designation: "Front-End Developer",
    dated: "April 2015 - Jan 2018",
    logo: morpheus,
    companyLink: "https://morpheuslabs.io/",
    description:
      "<p>Morpheus is a Singapore-based company providing businesses with an end-to-end Blockchain-Platform as a Service (BPaaS) solution for innovation and value creation. Its solution power-up blockchain application development offers ready-to-use development tools and applications in an agnostic environment with leading blockchain technologies to facilitate mass adoption. ",
  },
];
