export default [
  {
    id: 1,
    name: "Playing Chess",
  },
  {
    id: 2,
    name: "Reading Books",
  },
];
